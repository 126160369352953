import React, { useState } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

export default function Header({ includeLogo = true }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="w-full max-w-screen-xl mx-auto px-4-safe-area sm:px-6-safe-area">
      <Helmet>
        <body className={`${isExpanded ? 'overflow-hidden fixed' : ''} md:overflow-auto md:relative w-full`} />
      </Helmet>

      <nav className="h-16 md:h-20 flex items-center flex-wrap">
        {includeLogo && <Link to="/" className="z-30 leading-none font-old-standard h-5 text-2xl bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-400 via-purple-400 to-pink-400 animate-logo">ColourStormArt</Link>}
        <button className="text-white w-5 h-5 relative focus:outline-none md:hidden ml-auto z-30" onClick={() => toggleExpansion(!isExpanded)}>
          <span className="sr-only">Open main menu</span>
          <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <span aria-hidden="true" className={`${isExpanded ? 'rotate-45' : '-translate-y-1.5'} block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out`}></span>
            <span aria-hidden="true" className={`${isExpanded ? 'opacity-0' : ''} block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out`}></span>
            <span aria-hidden="true" className={`${isExpanded ? '-rotate-45' : 'translate-y-1.5'} block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out`}></span>
          </div>
        </button>
        <div className={`${isExpanded ? 'block' : 'hidden'} px-4 pt-20 absolute top-0 right-0 bottom-0 left-0 bg-black z-20 w-full md:p-0 md:bg-transparent md:relative md:inline-flex md:flex-grow md:w-auto`} id="navigation">
          <div className="w-full flex text-center flex-col text-xl md:text-base space-y-6 md:space-y-0 md:space-x-4 md:flex-row md:ml-auto md:w-auto md:p-0">
            <Link to="/" className="md:w-auto w-full px-2.5 py-1.5 rounded text-white items-center justify-center hover:underline">Home</Link>
            <Link to="/shop/" className="md:w-auto w-full px-2.5 py-1.5 rounded text-white items-center justify-center hover:underline">Shop</Link>
            <Link to="/about/" className="md:w-auto w-full px-2.5 py-1.5 rounded text-white items-center justify-center hover:underline">About</Link>
            <Link to="/contact/" className="md:w-auto w-full px-2.5 py-1.5 rounded text-black items-center justify-center bg-white hover:underline">Contact</Link>
          </div>
        </div>
      </nav>

    </header>
  )
}
