import React from "react";
import fbLogo from "../images/facebook-icon.svg";
import instaLogo from "../images/instagram-icon.svg";

export default function Footer(props) {
  return (
    <footer className="mt-4">
      <div className="w-full max-w-screen-xl mx-auto px-4-safe-area sm:px-6-safe-area py-4 flex flex-row justify-between items-center">
        <small className="text-gray-500">&copy; 2021 ColourStormArt</small>
        <div className="flex flex-inline">
          <a className="opacity-50 hover:opacity-100" href="https://en-gb.facebook.com/Colour-Stormart-273354659926671/" target="_blank" rel="noreferrer">
            <img alt="Facebook" className="mr-3" width="20" height="20" src={fbLogo} />
          </a>
          <a className="opacity-50 hover:opacity-100" href="https://www.instagram.com/colourstormart/" target="_blank" rel="noreferrer">
            <img alt="Instagram" width="20" height="20" src={instaLogo} />
          </a>
        </div>
      </div>
    </footer>
  )
}