import React, { useState } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, imageAlt }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl
  } = site.siteMetadata

  const seo = {
    title: title,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : undefined,
    imageAlt: imageAlt,
    url: `${siteUrl}${pathname}`
  }

  const [ogTitle, setOgTitle] = useState();

  const onHelmetChange = (data) => {
    setOgTitle(data.title);
  }

  return (
    <Helmet title={seo.title} defaultTitle={defaultTitle} titleTemplate={titleTemplate} onChangeClientState={onHelmetChange}>
      <meta name="description" content={seo.description} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content="website" />
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.imageAlt && <meta property="og:image:alt" content={seo.imageAlt} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  imageAlt: null
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`