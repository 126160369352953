import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";

export default function Layout({ includeHeader = true, children }) {
  return (
    <div className="text-white flex flex-col webkit-min-h-screen">
      <Helmet>
        <title>Colour Storm Art</title>
        <meta name="description" content="Colour Storm Art - Unique designs inspired by nature's formations" />
      </Helmet>
      {includeHeader && <Header/>}
      {children}
      <Footer/>
    </div>
  )
}